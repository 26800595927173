<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center gap-2">
      <UButton
        v-if="allowGoBack"
        icon="i-heroicons-arrow-left"
        color="gray"
        size="xs"
        variant="ghost"
        data-test="back-button"
        @click="onNavigate(lastPage)"
      />
      <Tooltip :text="title">
        <template #default="{ getTextRef }">
          <h3 :id="hash" :ref="getTextRef" class="text-xl font-semibold line-clamp-1 break-all">
            {{ title }}
          </h3>
        </template>
      </Tooltip>
    </div>
    <UButton
      color="gray"
      variant="ghost"
      icon="i-heroicons-x-mark-20-solid"
      size="xs"
      data-test="close-setting-btn"
      @click="closeSettings"
    />
  </div>
</template>

<script lang="ts" setup>
defineProps({
  hash: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  allowGoBack: {
    type: Boolean,
    default: true,
  },
  lastPage: {
    type: String,
    default: 'index',
  },
})

const route = useRoute()
const router = useRouter()
const { setSettingTab, closeSettings } = useBoardSettingsNavigator()

const onNavigate = async (page: string) => {
  const { id, ...cleanQuery } = route.query
  if (page === 'index' && id) {
    await router.push({ query: cleanQuery })
  }

  setSettingTab(page)
}
</script>
